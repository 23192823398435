.container {
    margin-left: 15px;
    margin-right: 15px;
    flex: 1;
    background-color: white;
    padding: 25px 15px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.row-info {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    justify-content: space-between;
    width: 100%;
}

.input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    gap: 10px;
}

.column {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 15px;
}

.info-prop {
    font-size: 18px;
    padding-bottom: 10px;
    text-align: left;
    font-weight: bold;
    margin-bottom: 5px;
}

.input {
    height: 50px;
    width: 100%;
    border: 1px solid gray;
    border-radius: 10px;
    padding: 0 10px;
    margin: 5px 0 15px;
}

.row-button {
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.button-save {
    width: 100%;
    height: 75px;
    background-color: #99ff99;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}

.button-text {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.th {
    background-color: #0065ad;
    color: white;
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
    font-size: 12px;
}

.td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
    font-size: 12px;
}

.td-blank {
    background-color: #f7faff;
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
    font-size: 12px;
}

.form-container {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.form-section {
    margin-bottom: 20px;
}

.section-title {
    margin-bottom: 10px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
}

.checkbox-section {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
}

.checkbox-label {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.checkbox-input {
    margin-right: 10px;
}

.body-diagram-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    height: 250px;
    margin-top: 10px;
}

.form-field {
    margin-bottom: 10px;
}

.medication-section {
    margin-top: 20px;
}

.medication-fields {
    display: flex;
    gap: 15px;
    margin-top: 10px;
}

.table-container {
    border: 1px solid #ccc;
    padding: 20px;
    background-color: #f9f9f9;
    margin-top: 20px;
}

@media (max-width: 768px) {
    .medication-fields {
        flex-direction: column;
    }
}
