.event-form {
    width: 90%;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    border: 1px solid #ccc;
    padding: 20px;
    background-color: #f9f9f9;
    margin-bottom: 25px;
  }
  
  .header-section,
  .local-section,
  .victim-section,
  .status-section {
    margin-bottom: 20px;
  }
  
  .header-row,
  .form-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  label {
    flex: 1;
    font-weight: bold;
    margin-right: 10px;
  }
  
  input[type="text"] {
    flex: 2;
    padding: 5px;
    border-radius: 3px;
  }
  
  .inline-input {
    display: flex;
    gap: 5px;
  }
  
  .inline-input input {
    width: 50px;
  }
  
  .radio-group {
    display: flex;
    gap: 10px;
  }
  
  .radio-group label {
    display: flex;
    align-items: center;
  }
  
  .radio-group input {
    margin-right: 5px;
  }
  